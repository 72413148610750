import { Routes, RouterModule } from '@angular/router';

//Layout for full pages.
export const Full_ROUTES: Routes = [
  {
    path: 'user',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  },
  {
    path: 'uploadfile',
    loadChildren: () => import('../../uploadfile/uploadfile.module').then(m => m.UploadfileModule)
  },

  {
    path: 'salespeople',
    loadChildren: () => import('../../salespeople/forms.module').then(m => m.FormModule)
  },

  {
    path: 'commission',
    loadChildren: () => import('../../commission/commission.module').then(m => m.FormModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'regions',
    loadChildren: () => import('../../regions/regions.module').then(m => m.RegionsModule)
  },
  {
    path: 'territories',
    loadChildren: () => import('../../territories/territories.module').then(m => m.TerritoriesModule)
  },
  {
    path: 'manufacturers',
    loadChildren: () => import('../../manufacturers/manufacturers.module').then(m => m.ManufacturersModule)
  },
  {
    path: 'hospitals',
    loadChildren: () => import('../../hospitals/hospitals.module').then(m => m.HospitalsModule)
  },
  {
    path: 'surgeons',
    loadChildren: () => import('../../surgeons/surgeons.module').then(m => m.SurgeonsModule)
  },
  {
    path: 'procedures',
    loadChildren: () => import('../../procedures/procedures.module').then(m => m.ProceduresModule)
  },
  {
    path: 'paymatrix',
    loadChildren: () => import('../../pay-matrix/pay-matrix.module').then(m => m.PayMatrixModule)
  }
];
