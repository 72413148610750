import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class CommissionService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private socket: Socket
  ) {
    this.socket.ioSocket.io.uri = this.configService.apiUrl;
  }
  onCommissionCalculationCompleted() {
    return new Observable(observer => {
      this.socket.on('CommissionCalculationCompleted', eventData => {
        observer.next(eventData);
        this.socket.disconnect();
      })
    });
  }

  getSalespersonCommissionData(selectedMonth, isPayoutInsertRequired): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getSalespersonCommissionData/${selectedMonth}/${isPayoutInsertRequired}`);
  }
  calculateCommission(selectedMonth) {
    return this.http.get(`${this.configService.apiUrl}/commission/calculateCommission/${selectedMonth}`);
  }
  calculateAnnualCommission(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/calculateAnnualCommission`, params);
  }
  storeAdjustment(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/storeAdjustment`, params);
  }
  getAdjustmentList(params): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getAdjustmentList/${params.salesPersonId}/${params.selectedMonthYear}`);
  }
  removeAdjustmentEntry(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/removeAdjustmentEntry`, params);
  }
  updateAjustment(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/updateAjustment`, params);
  }
  getCommissionStatus(selectedMonth): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getCommissionStatus/${selectedMonth}`);
  }


  getManufacturerList(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getManufacturerList`);
  }
  finalizMonth(selectedMonth): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/finalizMonth/${selectedMonth}`);
  }
  unLockMonth(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/unLockMonth`, params);
  }
  getSalespersonList(selectedMonthYear, selectedPrimaryTerritory): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getSalespersonList/${selectedMonthYear}/${selectedPrimaryTerritory}`);
  }
  getSelectedSalesPersonCommissionDetail(params): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getSelectedSalesPersonCommissionDetail/${params.salesPersonId}/${params.selectedMonthYear}`);
  }
  sendEmailToSalesRep(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/commission/sendEmailToSalesRep`, params);
  }
  updateSendAllStatus(selectedMonth): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/updateSendAllStatus/${selectedMonth}`);
  }
  getPrimaryTeritories(): Observable<any> {
    //return this.http.get(`${this.configService.apiUrl}/commission/getPrimaryTeritories`);
    return this.http.get(`${this.configService.apiUrl}/salesperson/getMasterDropdownsData`);
  }
  getCalculateCommissionHistory(selectedMonth): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getCalculateCommissionHistory/${selectedMonth}`);
  }
  getMonthNameForRunningCommissionProcess(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getMonthNameForRunningCommissionProcess`);
  }
  unFinalizMonth(selectedMonth): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/unFinalizMonth/${selectedMonth}`);
  }
  getSelectedSalespersonDetail(salesPersonId): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/salesperson/getSelectedSalespersonDetail/${salesPersonId}`);
  }
  getSelectedSalespersonDetailPaymatrix(params): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getSelectedSalespersonDetailPaymatrix/${params.salesPersonId}`);
  }


}